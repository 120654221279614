.home_bG{
    background-image: url('../../assets/images/home_Bg.svg');
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.glass{
    background: rgba(255, 255, 255, 0.59);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.home_p2{
    position: relative;
    background: #4c3c9b;
    left: 0;
    width: 99.9%;
    height: 240px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 12px;
    scroll-behavior: smooth;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 12px;
  }

  @media (width <= 767px) {
    .home_p2{
        position: relative;
        background: #4c3c9b;
        left: 0;
        width: 99.9%;
        height: 545px;
    }
  }