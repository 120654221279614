.main {
  background: radial-gradient(circle, #e5f0f1, #9ed5d6, #83dfe2);
}

.base_container {
  background-image: url('../../assets/images/new_base.svg');
  height: calc(100vh - 1px);
  width: 100vw;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.cartoon_frame {
  /* cartoons */
  background-image: url('../../assets/images/cartoons.svg');
  height: calc(100vh - 1px);
  width: 100vw;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}
