.header-nebula {
	/* flex justify-center h-[5rem] bg-[black] */
	display: flex;
	justify-content: space-between;
	height: 3rem !important;
	/* background-color: #0853cc !important; */
	background-color:#002626 !important;
}

.header__wrapper {
	display: flex;
	width: 100%;
	justify-content: space-between;
	color: white;
	padding: 0 2rem 0 2rem;
}

.header__logo {
	font-family: Roboto;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	letter-spacing: 0em;
	text-align: left;
}

.header__items {
	display: flex;
	width: 20%;
	justify-content: center;
	align-items: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	gap: 5px;
}

@media (max-width: 500px) {
	.header__items {
		display: flex;
		width: 20%;
		justify-content: center;
		align-items: center;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		gap: 5px;
		flex-direction: column;
	}
}

/* flex w-20 justify-between items-center px-2 */

/* flex justify-center items-center text-3xl */

/* border border-solid h-max */

.sidemenu__menu {
	/* border: 1px solid black !important; */
	/* background-color:  !important; */
	border-top: none !important;

	height: 92vh;
}

.sidemenu__menu-mobile {
	/* border: 1px solid black !important; */
	background-color: #f5f6f7 !important;

	height: 100%;
}
